import * as Highcharts from 'highcharts';
import {useEffect, useRef, useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import {Card, CardContent} from '@mui/material';
import {percentageFormatter} from '../../helpers/formatters';

interface ISuccessFactorProps {
    final: any[];
}

const transform = [
    {
        name: 'Business coach support',
        value: 'BIZ_SUCCESS_FAC_COACH',
    },
    {
        name: 'Increased self-confidence in the business idea',
        value: 'BIZ_SUCCESS_FAC_CONF',
    },
    {
        name: 'Having found a financier',
        value: 'BIZ_SUCCESS_FAC_FINANCIER',
    },
    {
        name: 'Other',
        value: 'other',
    },
];

const SuccessFactor = ({final}: ISuccessFactorProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [options, setOptions] = useState<Highcharts.Options>();

    const calculateSubmissions = () => {
        const data = transform
            .map((el) => {
                return {
                    name: el.name,
                    y: final.filter((submission: any) => submission.biz_success_fac === el.value).length,
                };
            })
            .sort((a, b) => b.y - a.y);

        const dashboardOptions: Highcharts.Options = {
            chart: {
                type: 'column',
            },
            title: {
                text: 'Scale up factors for business start up',
                align: 'left',
            },
            yAxis: {
                min: 0,
                title: {
                    text: '',
                },
            },
            xAxis: {
                categories: data.map((el) => el.name),
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                },
            },
            series: [
                {
                    showInLegend: false,
                    name: 'Number',
                    data: data.map((el) => el.y),
                    type: 'column',
                    dataLabels: {
                        enabled: true,
                        formatter: percentageFormatter(data),
                    },
                },
            ],
        };

        setOptions(dashboardOptions);
    };

    useEffect(() => {
        calculateSubmissions();
    }, []);

    return (
        <Card className='chart-container'>
            <CardContent>
                {options && (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                        ref={chartComponentRef}
                    />
                )}
            </CardContent>
        </Card>
    );
};

export default SuccessFactor;
